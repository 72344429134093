import {GlobalContext} from 'contexts/globalContext';
import {LoginContext} from 'contexts/loginContext';
import navigation from 'navigation.json';
import {canUseFeature} from 'permissions/appPermission';
import {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import NavElement from './NavElement';

function SelectedModule(props) {
  const {module} = props;
  const pathname = useLocation().pathname;
  const [elements, setElements] = useState({});
  const {user} = useContext(LoginContext);
  const {brand, vipBrand} = useContext(GlobalContext);

  useEffect(() => {
    if (module != null) {
      setElements(navigation[module]?.items || {});
    }
  }, [module, user, brand, vipBrand]);

  return (
    <div className='nav-element-group'>
      {Object.values(elements).map((element, index) => {
        if (canUseFeature(user, element?.app, element?.feature, element?.app === 'brands' ? brand?.id : vipBrand, null, element.anySubID)) {
          return <NavElement key={index} element={element} pathname={pathname} />;
        } else return null;
      })}
    </div>
  );
}

export default SelectedModule;
