import LoadSvg from 'components/LoadSvg-v3';
import {LoginContext} from 'contexts/loginContext';
import {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
//import Navigator from 'components/Navigator-v2'
import IPanelV2Ad from 'components/IPanelV2Ad';
import Modal from 'components/Modal/Modal';
import {GlobalContext} from 'contexts/globalContext';
import {ModalContext} from 'contexts/modalContext';
import useMultilang from 'intl/useMultilang';
import {v4 as uuidv4} from 'uuid';
import SelectedModule from './SelectedModule';
import UserModal from './UserModal';
//import { isMobileOnly } from "react-device-detect"
//import { MobileProvider } from "contexts/mobileContext"
//import useScreenOrientation from "hooks/useScreenOrientation"

export default function MainPanel(props) {
  const {loggedIn, user, newVersionAvailable} = useContext(LoginContext);
  const {brand, vipBrand, vipBrands} = useContext(GlobalContext);
  const {addModal, popModal} = useContext(ModalContext);
  const [userMessage, setUserMessage] = useState('');
  const [minimalize, setMinimalize] = useState(false);
  const {getT} = useMultilang();
  //const orientation = useScreenOrientation()
  //const [naviOpen, setNaviOpen] = useState(false)
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const module = pathname.split('/')[1];
  const menu = pathname.split('/')[2];

  useEffect(() => {
    let name = user?.firstName != null && user?.firstName !== '' && `Hello ${user?.firstName}!`;
    name += user?.email != null ? ' - ' + user?.email : '';
    typeWriter(name, 0, setUserMessage);
    // eslint-disable-next-line
  }, [user]);

  const handleMinimize = () => setMinimalize(!minimalize);

  const typeWriter = (text, i, setter, callback) => {
    if (i < text.length) {
      setter(text.substring(0, i + 1));
      setTimeout(function () {
        typeWriter(text, i + 1, setter, callback);
      }, 50);
    } else {
      if (typeof callback === 'function') callback();
    }
  };

  const openUserModal = () => {
    addModal(
      <Modal key={uuidv4()} className='user-modal' onClickLayout={popModal}>
        <UserModal />
      </Modal>
    );
  };

  if (module === 'external') return <div className='external-main-context'>{props.children}</div>;

  if (!loggedIn) return null;

  // if(isMobileOnly) {
  //   if((orientation || "").includes("landscape")) return <div className="change-orientation">
  //     <div className="icon-container">
  //       <LoadSvg className="rotate" name="reply" />
  //       <LoadSvg className="phone" name="tablet" />
  //     </div>
  //   </div>
  //   return (
  //     <div className={`main-mobile-content ${module}-${menu}`}>
  //       <MobileProvider>
  //         {props.children}
  //       </MobileProvider>
  //     </div>
  //   )
  // } else {
  return (
    <div className={`iPanel ${minimalize ? 'minimalized' : ''} navi-closed `}>
      <div className='main-left-side'>
        <div className='nav-container'>
          <div className='nav-toggler'>
            <LoadSvg name='group' onClick={handleMinimize} />
          </div>
          <SelectedModule module={module} />
          {/* <div className="nav-element-outer">
              <div className="nav-element" onClick={handleLogout}>
                <div className="svg-container"><LoadSvg name="SVGExit" /></div>
                <div className="nav-element-title">Logout</div>
              </div>
            </div> */}
        </div>
      </div>
      <div className='main-right-side'>
        <span className='iPanel-text' onClick={() => navigate('/')}>
          iPanel
        </span>
        <div className='top-bar'>
          <div className='breadcrumb'>
            <span className='text text-module font-extraLarge'>{module}</span>
            {module === 'vip' && vipBrands?.[vipBrand]?.name != null && (
              <>
                <span className='text font-extraLarge'>/</span>
                <span className='text text-vip font-extraLarge'>{vipBrands?.[vipBrand]?.name}</span>
              </>
            )}
            {module === 'brand' && brand?.brandName != null && (
              <>
                <span className='text font-extraLarge'>/</span>
                <span className='text text-brand font-extraLarge'>{brand?.brandName}</span>
              </>
            )}
            {menu != null && (
              <span className='text text-menu font-extraLarge'>
                <span className='text text-separator font-extraLarge'>/</span>
                {getT(`global.breadCrumbs.menu.${menu}`)}
              </span>
            )}
          </div>
          <IPanelV2Ad className={'btn v2-btn'} />
          <div className='user-data' style={{fontFamily: 'Courier'}}>
            <span className='user-text font-small'>
              {userMessage}
              <span className='blinking-cursor-base'>_</span>
            </span>
          </div>
          <div className='user-modal-toggler' onClick={openUserModal}>
            <LoadSvg name='gridDots' />
          </div>
          {/* <Navigator module={module} toggleNaviOpen={handleNaviOpen} /> */}
        </div>
        <div className='main-context'>
          {props.children}
          {newVersionAvailable && (
            <div className='new-version-available' onClick={() => window.location.reload()}>
              {getT('global.newVersionAvailable')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
  //}
}
