import LoadSvg from 'components/LoadSvg-v3';
import {GlobalContext} from 'contexts/globalContext';
import {LoginContext} from 'contexts/loginContext';
import useMultilang from 'intl/useMultilang';
import {canUseFeature} from 'permissions/appPermission';
import {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';

function NavElement(props) {
  const {element, pathname} = props;

  const navigate = useNavigate();
  const {user} = useContext(LoginContext);
  const {brand, vipBrand} = useContext(GlobalContext);
  const {getT} = useMultilang();
  console.log('NavElement', user?.roles?.includes('globalAdmin'));
  const [open, setOpen] = useState(false);
  const handleClickOnElement = (path) => {
    if (element?.shouldNavigateToV2 && !user?.roles?.includes('globalAdmin')) {
      window.location.href = 'https://barsoft.hu/ipanel/brand/dashboard';
      return;
    }
    if (path !== 'brand/menu' || (path === 'brand/menu' && canUseFeature(user, element?.app, 'listItems', brand?.brandID))) {
      navigate(path);
    }
    setOpen(pathname === '/' + element.pathName ? !open : true);
  };

  const handleClickOnSubElement = (path) => {
    if (element?.shouldNavigateToV2 && !user?.roles?.includes('globalAdmin')) {
      window.location.href = 'https://barsoft.hu/ipanel/brand/dashboard';
      return;
    }
    navigate(path);
  };

  return (
    <div className='nav-element-outer' key={element.title}>
      {element?.subNavigation != null && <LoadSvg name='chevron' className={`sub-element-toggler ${open ? 'open' : 'closed'}`} onClick={() => setOpen(!open)} />}
      <div
        className={`nav-element ${pathname === '/' + element.pathName ? 'selected' : ''} ${element?.subNavigation != null ? 'with-sub' : 'no-sub'}`}
        onClick={() => {
          handleClickOnElement(element.pathName);
        }}>
        <div className='svg-container'>
          <LoadSvg name={element.iconName} />
        </div>
        <div className='nav-element-title'>{getT(element.title)}</div>
      </div>
      {element?.subNavigation != null && (
        <div className={`sub-elements ${open ? 'open' : 'closed'}`}>
          {Object.values(element?.subNavigation || {}).map((subElement) => {
            if (canUseFeature(user, subElement?.app, subElement?.feature, subElement?.app === 'brands' ? brand?.id : vipBrand, null, subElement.anySubID)) {
              return (
                <div
                  key={subElement.title}
                  className={`nav-element ${pathname === '/' + subElement.pathName ? 'selected' : ''}`}
                  onClick={() => {
                    handleClickOnSubElement(subElement.pathName);
                  }}>
                  {/* <LoadSvg name="subMenu" /> */}
                  <div className='nav-element-title ml-2'>{getT(subElement.title)}</div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      )}
    </div>
  );
}
export default NavElement;
